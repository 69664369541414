import { combineReducers } from 'redux';
import usecase from './usecase';

const rootReducer = combineReducers({
    usecase
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
