exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cloud-tsx": () => import("./../../../src/pages/cloud.tsx" /* webpackChunkName: "component---src-pages-cloud-tsx" */),
  "component---src-pages-data-dap-tsx": () => import("./../../../src/pages/data-dap.tsx" /* webpackChunkName: "component---src-pages-data-dap-tsx" */),
  "component---src-pages-data-forecast-tsx": () => import("./../../../src/pages/data-forecast.tsx" /* webpackChunkName: "component---src-pages-data-forecast-tsx" */),
  "component---src-pages-data-fraud-tsx": () => import("./../../../src/pages/data-fraud.tsx" /* webpackChunkName: "component---src-pages-data-fraud-tsx" */),
  "component---src-pages-data-recommend-tsx": () => import("./../../../src/pages/data-recommend.tsx" /* webpackChunkName: "component---src-pages-data-recommend-tsx" */),
  "component---src-pages-data-tsx": () => import("./../../../src/pages/data.tsx" /* webpackChunkName: "component---src-pages-data-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-offering-tsx": () => import("./../../../src/pages/offering.tsx" /* webpackChunkName: "component---src-pages-offering-tsx" */),
  "component---src-pages-privacy-aicc-tsx": () => import("./../../../src/pages/privacy-aicc.tsx" /* webpackChunkName: "component---src-pages-privacy-aicc-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-alphabox-tsx": () => import("./../../../src/pages/product-alphabox.tsx" /* webpackChunkName: "component---src-pages-product-alphabox-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-puffy-privacy-tsx": () => import("./../../../src/pages/puffy-privacy.tsx" /* webpackChunkName: "component---src-pages-puffy-privacy-tsx" */),
  "component---src-pages-tac-tsx": () => import("./../../../src/pages/tac.tsx" /* webpackChunkName: "component---src-pages-tac-tsx" */),
  "component---src-pages-vision-dob-tsx": () => import("./../../../src/pages/vision-dob.tsx" /* webpackChunkName: "component---src-pages-vision-dob-tsx" */),
  "component---src-pages-vision-fastlane-tsx": () => import("./../../../src/pages/vision-fastlane.tsx" /* webpackChunkName: "component---src-pages-vision-fastlane-tsx" */),
  "component---src-pages-vision-inference-tsx": () => import("./../../../src/pages/vision-inference.tsx" /* webpackChunkName: "component---src-pages-vision-inference-tsx" */),
  "component---src-pages-vision-kia-tsx": () => import("./../../../src/pages/vision-kia.tsx" /* webpackChunkName: "component---src-pages-vision-kia-tsx" */),
  "component---src-pages-vision-tsx": () => import("./../../../src/pages/vision.tsx" /* webpackChunkName: "component---src-pages-vision-tsx" */),
  "component---src-pages-warmup-tsx": () => import("./../../../src/pages/warmup.tsx" /* webpackChunkName: "component---src-pages-warmup-tsx" */),
  "component---src-pages-welcome-tsx": () => import("./../../../src/pages/welcome.tsx" /* webpackChunkName: "component---src-pages-welcome-tsx" */)
}

