const SET = 'usecase/SET' as const;
const CLEAR = 'usecase/CLEAR' as const;

export const set = (name: string) => ({
  type: SET,
  name
});

export const clear = () => ({
  type: CLEAR
});

type UsecaseAction =
  | ReturnType<typeof set>
  | ReturnType<typeof clear>;

type UsecaseState = {
  name: string,
  active: boolean
}

const initialState: UsecaseState = {
  name: '',  
  active: false
}

function usecase(
  state: UsecaseState = initialState,
  action: UsecaseAction
): UsecaseState {
  switch (action.type) {
    case SET:      
      return { name: action.name, active: true }
    case CLEAR:
      return { name: '', active: false }
    default:
      return state;
  }
}

export default usecase